<template>
  <div class="course-box">
    <div class="tab-list-box flex align-center justify-between flex-wrap">
      <div class="tab-list flex align-center flex-wrap">
        <div class="title" style="margin-bottom: 10px">分类：</div>
        <div class="tab-item"
             @click="toggleTab(0)"
             :class="{current: tabCurrent === 0}" :key="`tabList_-1`">全部</div>
        <div class="tab-item"
             @click="toggleTab(item.id)"
             :class="{current: tabCurrent === item.id}"
             v-for="(item, index) in tabList" :key="`tabList_${index}`">
          {{ item.text }}
        </div>
      </div>
      <el-button type="primary" style="margin-bottom: 10px" @click="uploadFile" v-if="role === 4">上传资源</el-button>
    </div>
    <el-scrollbar class="course-list-box" style="height: 1%; flex: 1">
    <div class="course-list">
      <div class="course-item" v-for="(item, index) in courseList" :key="`courseList_${index}`">
        <div class="item-img">
          <div class="item-img-box">{{item.title}}</div>
        </div>
        <div class="item-content">
          <div class="item-title text-overflow" :title="item.secondTitle">
            {{ item.secondTitle }}
          </div>
          <div class="item-bottom">
            <div class="left">
              <img :src="item.avatar ? item.avatar : require('@/assets/image/blank_headPic.png')">
              <div class="name">{{ item.person }}</div>
            </div>
            <div style="display: flex; align-items: center;">
              <el-link type="primary" :underline="false" @click="editCourse(item)" style="margin-right: 12px;">修改</el-link>
              <router-link class="to-detail" :to="{path: role === 4 ? '/teacher/course/detail' : '/studentHome/myCourses/detail', query: {id: item.id}}">查看详情</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    </el-scrollbar>
<!--    <div>分页</div>-->

    <el-dialog title="上传资源" :visible.sync="dialogFormVisible" width="615" @close="resetForm('ruleForm')">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
        <el-form-item label="资源标题:" prop="name">
          <el-input v-model="ruleForm.name" placeholder="默认显示文件名称，字数限制2-18字"></el-input>
        </el-form-item>
        <el-form-item label="资源分类:" prop="class">
          <el-select v-model="ruleForm.class" placeholder="请选择" style="width: 100%">
            <el-option :label="item.text" :value="item.id" v-for="(item, index) in tabList" :key="`tabList1_${index}`"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="资源:" prop="fileName">
<!--          https://jsonplaceholder.typicode.com/posts/-->
          <el-upload
              class="upload-demo"
              action=""
              :on-success="handleAvatarSuccess"
              :on-remove="handleRemove"
              :on-error="handleAvatarError"
              :before-upload="beforeAvatarUpload"
              accept=".mp4, .avi, .pdf, .ppt, .pptx, .doc, .docx, .xls, .xlsx"
              :file-list="fileList"
          >
            <el-button size="small" type="primary">点击上传</el-button><span style="margin-left: 10px">{{ruleForm.fileName}}</span>
            <div slot="tip" style="color: #999; font-size: 12px">文件支持格式为mp4、avi、pdf、word、excel、ppt</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm('ruleForm')">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      role: Number(localStorage.getItem('role')),
      tabCurrent: 0,
      tabList: [
        // {
        //   id: 0,
        //   text: '全部',
        // },
        {
          id: 1,
          text: '直播带货技巧',
        },
        {
          id: 2,
          text: '短视频制作',
        },
        {
          id: 3,
          text: '直播脚本',
        },
        {
          id: 4,
          text: '直播话术',
        },
        {
          id: 5,
          text: '直播间违禁词',
        },
      ],
      courseList: [
        {
          id: 1,
          type: 1,
          title: '脚本案例——商品类',
          secondTitle: '商品类短视频脚本',
          avatar: require('@/assets/image/teacherDataCenter/avatar.png'),
          person: 'admin'
        },
        {
          id: 2,
          type: 1,
          title: '脚本案例——美食类',
          secondTitle: '任务2 美食类短视频拍摄与运营',
          avatar: '',
          person: '吴老师'
        },
        {
          id: 3,
          type: 2,
          title: '脚本案例——鬼畜类',
          secondTitle: '任务3 鬼畜类短视频拍摄与运营',
          avatar: '',
          person: '李老师'
        },
        {
          id: 4,
          type: 3,
          title: '脚本案例——vlog',
          secondTitle: '任务4 技术流短视频拍摄与运营',
          avatar: require('@/assets/image/teacherDataCenter/avatar.png'),
          person: 'admin'
        },
        {
          id: 5,
          type: 4,
          title: '脚本案例——舞蹈类',
          secondTitle: '任务5 舞蹈类短视频拍摄与运营',
          avatar: require('@/assets/image/teacherDataCenter/avatar.png'),
          person: 'admin'
        },
        {
          id: 6,
          type: 4,
          title: '脚本案例——搞笑类',
          secondTitle: '任务6 搞笑类短视频拍摄与运营',
          avatar: require('@/assets/image/teacherDataCenter/avatar.png'),
          person: 'admin'
        },
        {
          id: 7,
          type: 5,
          title: '脚本案例——风景类',
          secondTitle: '任务7 风景类短视频拍摄与运营',
          avatar: require('@/assets/image/teacherDataCenter/avatar.png'),
          person: 'admin'
        },
        {
          id: 8,
          type: 1,
          title: '脚本案例——vlog',
          secondTitle: '任务8 vlog类短视频拍摄与运营',
          avatar: require('@/assets/image/teacherDataCenter/avatar.png'),
          person: 'admin'
        },
      ],
      copyCourseList: [
        {
          id: 1,
          type: 1,
          title: '脚本案例——商品类',
          secondTitle: '商品类短视频脚本',
          avatar: require('@/assets/image/teacherDataCenter/avatar.png'),
          person: 'admin'
        },
        {
          id: 2,
          type: 1,
          title: '脚本案例——美食类',
          secondTitle: '任务2 美食类短视频拍摄与运营',
          avatar: '',
          person: '吴老师'
        },
        {
          id: 3,
          type: 2,
          title: '脚本案例——鬼畜类',
          secondTitle: '任务3 鬼畜类短视频拍摄与运营',
          avatar: '',
          person: '李老师'
        },
        {
          id: 4,
          type: 3,
          title: '脚本案例——vlog',
          secondTitle: '任务4 技术流短视频拍摄与运营',
          avatar: require('@/assets/image/teacherDataCenter/avatar.png'),
          person: 'admin'
        },
        {
          id: 5,
          type: 4,
          title: '脚本案例——舞蹈类',
          secondTitle: '任务5 舞蹈类短视频拍摄与运营',
          avatar: require('@/assets/image/teacherDataCenter/avatar.png'),
          person: 'admin'
        },
        {
          id: 6,
          type: 4,
          title: '脚本案例——搞笑类',
          secondTitle: '任务6 搞笑类短视频拍摄与运营',
          avatar: require('@/assets/image/teacherDataCenter/avatar.png'),
          person: 'admin'
        },
        {
          id: 7,
          type: 5,
          title: '脚本案例——风景类',
          secondTitle: '任务7 风景类短视频拍摄与运营',
          avatar: require('@/assets/image/teacherDataCenter/avatar.png'),
          person: 'admin'
        },
        {
          id: 8,
          type: 1,
          title: '脚本案例——vlog',
          secondTitle: '任务8 vlog类短视频拍摄与运营',
          avatar: require('@/assets/image/teacherDataCenter/avatar.png'),
          person: 'admin'
        },
      ],

      dialogFormVisible: false,
      ruleForm: {
        name: '',
        class: '',
        fileName: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入资源标题', trigger: 'blur' },
          { min: 1, max: 18, message: '默认显示文件名称，字数限制2-18字', trigger: 'blur' }
        ],
        class: [
          { required: true, message: '请选择资源分类', trigger: 'change' }
        ],
        fileName: [
          { required: true, message: '请选择文件', trigger: 'change' },
        ],
      },
      fileList: [],
    }
  },
  mounted() {

  },
  methods: {
    toggleTab(val) {
      this.tabCurrent = val
      if (val === 0) {
        this.courseList = this.copyCourseList
        return
      }
      this.courseList = this.copyCourseList.filter(item => item.type === val)
    },
    uploadFile() {
      this.dialogFormVisible = true
    },
    editCourse(item) {
      console.log({item});
      this.dialogFormVisible = true;
      this.ruleForm.name = item.secondTitle;
      this.ruleForm.class = this.tabList[item.type + 1].text;
      this.ruleForm.fileName = item.fileName || '脚本.mp4';
    },
    handleAvatarSuccess(res, file) {
      this.ruleForm.fileName = file.name
      let obj = {
        name: file.name,
        url: ''
      }
      this.fileList.splice(0, 1, obj)
    },
    handleAvatarError(err, file) {
      this.ruleForm.fileName = file.name
      let obj = {
        name: file.name,
        url: ''
      }
      this.fileList.splice(0, 1, obj)
    },
    beforeAvatarUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error('上传资源大小不能超过 10MB!');
      }
      return isLt10M;
    },
    handleRemove(file, fileList) {
      this.ruleForm.fileName = ''
      this.fileList = []
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {
            id: this.courseList.length + 1,
            type: 1,
            title: '脚本案例——商品类',
            secondTitle: this.ruleForm.name,
            avatar: localStorage.getItem('teacherAvatar'),
            person: localStorage.getItem('nickname')
          }
          let arr = JSON.parse(JSON.stringify(this.courseList))
          arr.push(obj)
          this.courseList = arr

          let arr1 = JSON.parse(JSON.stringify(this.copyCourseList))
          arr1.push(obj)
          this.copyCourseList = arr1
          this.$message.success('添加成功')
          this.resetForm(formName)
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogFormVisible = false
      this.ruleForm = {
        name: '',
        class: '',
        fileName: ''
      }
      this.fileList = []
    }
  }
}
</script>

<style scoped lang="scss">
.course-box {
  padding: 30px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .tab-list-box {
    background: #F3F6FF;
    border-radius: 4px;
    padding: 15px 24px 5px;
    .tab-list {
      color: #414141;
      .tab-item {
        cursor: pointer;
        padding: 5px 10px;
        border-radius: 2px;
        transition: all .3s;
        margin: 0 10px 10px 0;
        &.current {
          color: #1F34E4;
          background: #BEC3F2;
        }
      }
    }
  }
  .course-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;
    width: calc(100% + 16px);
    .course-item {
      width: calc(25% - 16px);
      margin: 20px 0 0 16px;
      .item-img {
        width: 100%;
        height: 0;
        padding-bottom: 58%;
        position: relative;
        .item-img-box {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: url("../../../assets/image/teacherDataCenter/course-bg.png") no-repeat;
          background-size: cover;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 24px;
          font-weight: 500;
          padding: 20px;
          box-sizing: border-box;
        }
      }
      .item-content {
        border: 1px solid #ddd;
        border-top: none;
        border-radius: 0 0 4px 4px;
        padding: 10px 16px 16px;
        .item-title {
          font-size: 16px;
          height: 23px;
        }
        .item-bottom {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .left {
            display: flex;
            align-items: center;
            img {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              background: #D9D9D9;
            }
            .name {
              margin-left: 10px;
            }
          }
          .to-detail {
            color: #2A3EE5;
          }
        }
      }
    }
  }
}
.course-list-box {
  :deep(.el-scrollbar__wrap) {
    overflow-x: hidden;
  }
}
</style>